
import { defineComponent, onMounted, ref } from 'vue';
import router from '@/router';
import { Http } from '@/sdk';

export default defineComponent({
  setup() {
    function deleteAllCookies() {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }

    const vCode = ref();
    const verificationResult = ref(true);
    const loginErrorMsg = ref();
    const verificationClick = async () => {
      const result = await Http.get('api/v1/admin/login?passcode=' + vCode.value);
      if (result?.data?.data?.is_success === true) {
        verificationResult.value = true;
        router.push('/login/reset');
      } else {
        loginErrorMsg.value = result?.data.msg;
        verificationResult.value = false;
      }
    };

    onMounted(async () => {
      // deleteAllCookies();
      await Http.get('/api/v1/admin/logout');
    });

    return { vCode, verificationClick, verificationResult, loginErrorMsg };
  }
});
